@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$color-footer-foreground: $color-navigation-foreground !default;
$color-footer-border: $color-navigation-border !default;

.root {
  border-top: 1px solid $color-footer-border;
  background-color: $color-footer-background;
  color: $color-footer-foreground;
  position: relative;
  overflow: hidden;
}

.container {
  max-width: $wrapper-max-width;
  margin: 0 auto;
}

.main {
  padding: $space-md 0;

  @include md-max {
    flex-direction: column;
  }
}

.row {
  flex-flow: row;
  display: flex;
  width: 100%;

  @include md-max {
    flex-direction: column;
  }
}

.links {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin-left: $space-xxl;

  @include md-max {
    flex-direction: column;
    margin-left: 0;
  }
}

.company {
  padding: $space-sm;
}

.list {
  flex: 1;
  order: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include md-max {
    align-items: flex-start;
  }

  @include md-max {
    order: 1;
  }
}

.socials {
  display: flex;
  justify-content: center;

  @include lg-min {
    justify-content: flex-start;
  }
}

.tagline {
  margin-top: $space-sm;
}

.to-top {
  height: $space-lg;
  width: $space-lg;
  color: $color-primary-500;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 $color-shading-400;

  :hover {
    color: $color-primary-500;
  }

  @include md-max {
    order: 0;
    position: absolute;
    right: $space-sm;
  }

  @include xs-max {
    order: 0;
    position: relative;
    right: 0;
    margin-left: auto;
  }
}
