@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $color-footer-foreground: $color-black,
);

.links {
  order: 1;
  margin-left: 0;
  margin-right: $space-xxl;

  @include md-max {
    margin-left: 0;
    margin-right: 0;
  }
}

.company {
  order: 2;
  padding: $space-sm $space-lg;

  @include md-max {
    padding: $space-lg;
  }
}

.socials {
  justify-content: flex-start;
}
